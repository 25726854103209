.card-container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 4px;
    flex: none;
    order: 1;
    flex-grow: 0;
    padding-top: 5px;
    padding-bottom: 5px;
}

.restaurant-title{
    height: 20px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #25201F;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.restaurant-subtitle{
    height: 19px;
    font-size: 12px;
    line-height: 15px;
    color: #817F7E;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.restaurant-image-container-user-facing-app {
    height: 210px;
    flex: none;
    order: 2;
    flex-grow: 0;
    width: 100%;
}
.restaurant-image-user-facing-app {
    height: 210px;
    border-radius: 8px;
    object-fit: cover;
    width: 100%;
}
.map-pin{
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 10px;
    position: fixed;
    width: 25px;
    height: 19px;
    left: 10px;
    top: 10px;
    background: #FFFFFF;
    border: 1px solid #F7F7F7;
    box-shadow: 0px 0.5px 8px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
}