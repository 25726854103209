.overlay {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .7);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
}

.modalOrderConfirmation-wide {
  position: fixed;
  z-index: 20;
  background: #fff;
  width: 100%;
  height: 95%;
  max-width: 600px;
  overflow: scroll;
  border-radius: 25px;
  /* Center the modalOrderConfirmation */
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.modalOrderConfirmation-wide::-webkit-scrollbar {
  display: none;
}

.modalOrderConfirmation-narrow {
  position: fixed;
  z-index: 20;
  background: #fff;
  width: 100%;
  height: 100%;
  max-width: 500px;
  /* Center the modalOrderConfirmation */
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  overflow: scroll;
}

.modalOrderConfirmation-narrow::-webkit-scrollbar {
  display: none;
}

.modalOrderConfirmation-header {
  /*background: orangered;*/
  /*padding: 10px 20px;*/
  display: block;
  justify-content: space-between;
  height: 40px;
}

.modalOrderConfirmation-title {
  margin-top: 20px;
}

.modalOrderConfirmation-main {
  padding: 7%;
  padding-top: 0;
  padding-bottom: 0;
  min-height: 77%;
}

.button {
  background: green;
  border: none;
  padding: 10px 20px;
  color: #fff;
  cursor: pointer;

}

.button:hover {
  background: orange;
}

.modalOrderConfirmation-order-item {
  display: block;
  width: 100%;
  overflow: auto;
}

.modalOrderConfirmation-order-item-title {
  font-weight: 700;
  font-size: 18px;
  float: left;
}

.modalOrderConfirmation-order-item-price {
  font-weight: 700;
  font-size: 18px;
  float: right;
}

.modalOrderConfirmation-order-item-info {
  display: block;
  border-bottom: solid 1.5px #ebebeb;
}

.modalOrderConfirmation-order-item-image-wide {
  height: 200px;
  width: 40%;
  overflow: hidden;
  object-fit: cover;
  float: right;
  margin-top: -10px;
}

.modalOrderConfirmation-order-item-image-narrow {
  height: 140px;
  width: 40%;
  overflow: hidden;
  object-fit: cover;
  float: right;
  margin-top: -10px;
}

.modalOrderConfirmation-quantityButton-container{
  /*display: flex;*/
  justify-content: center;
  width: 125px;
}

.modalOrderConfirmation-quantityButton{
  display: flex;
  justify-content: space-between;
  border: none;
  background: rgb(245, 245, 245);
  border-radius: 30px;
  width: 125px;
  margin-bottom: 10px;
}

.modalOrderConfirmation-quantityButton-btn{
  border: none;
  background: none;
  color: black;
  font-weight: 700;
  font-size: large;
  width: 50%;
}

.modalOrderConfirmation-quantityButton-quantity {
  width: 20%;
  display: flex;
  justify-content: center;
  font-weight: 700;
}

.modalOrderConfirmation-quantityButton-trash-image {
  width: 16px;
  height: 16px;
  margin-top: 5px;
}

.modalOrderConfirmation-order-item-options {
  width: 55%;
  float: left;
  margin-top: -10px;

}

.modalOrderConfirmation-order-item-option {
  font-size: 14px;
  /*margin-left: 2%;*/
  margin-bottom: 10px;
}

.modalOrderConfirmation-order-item-option-title {
  font-weight: 700;
  margin-bottom: 0px;
}

.modalOrderConfirmation-order-item-option-value {
  margin-left: 2%;
}

.modalOrderConfirmation-order-item-option-value-title {
  margin-top: 0px;
  margin-bottom: 0px;
}

.modalOrderConfirmation-order-special-instructions-title {
  font-size: 14px;
  /*margin-left: 1.5%;*/
  font-weight: 700;
  margin-bottom: 0px;
  width: 55%;
}

.modalOrderConfirmation-order-special-instructions-value {
  font-size: 14px;
  margin-left: 2%;
  margin-bottom: 10px;
  width: 55%;
}

.modalOrderConfirmation-order-wide {
	/*padding-bottom: 17%;*/
}

.modalOrderConfirmation-order-narrow {
	/*padding-bottom: 25%;*/
}

.modalOrderConfirmation-subtotal {
  display: block;
}

.modalOrderConfirmation-tax {
  display: block;
}

.modalOrderConfirmation-total {
  display: block;
  margin-top: 5px;
  margin-bottom: 40px;
}

.modalOrderConfirmation-totals-label {
  float: left;
}

.modalOrderConfirmation-totals-value {
  float: right;
}

.modalOrderConfirmation-totals-label-bold {
  float: left;
  font-weight: 700;
}

.modalOrderConfirmation-totals-value-bold {
  float: right;
  font-weight: 700;
}

.modalOrderConfirmation-total-divider {
  border-top: solid 1.5px #ebebeb;
}