@import url("https://fonts.googleapis.com/css2?family=Anek+Telugu&display=swap");

html {
	--logo-color: #f04a25;
	--navbar-color: #1a2228;
	/*background: rgba(0,0,0,0.02);*/
}

*:focus {
    outline: none;
}

.menu-app {
	font-family: 'Anek Telugu', sans-serif;
	max-width: 1600px;
	margin: 0 auto;
	background: white;
}

.body-modal-open {
	overflow-x: hidden;
}

button {
	cursor: pointer;
}

.body {
	
}

.rest-of-body {
	width: 95%;
	margin: 0 auto;
}

.overflow-unset {
	overflow-x: unset;
}

.sticky-header {
	position: sticky;
	top: 0px;
	padding-top: 10px;
	background: white;
	width: 100.1%;
	margin-left: -0.1%;
	overflow-x: hidden;
	z-index: 2;
}

.sticky-header-fixed-wide {
	position: fixed;
	top: 0px;
	overflow-x: hidden;
	margin-left: -0.1%;
	/*width: 955px;*/
}

.sticky-header-fixed-narrow {
	position: fixed;
	top: 0px;
	overflow-x: hidden;
	margin-left: -0.1%;
}

.sticky-header-placeholder-wide {
	width: 100%;
	height: 139px;
}

.sticky-header-placeholder-narrow {
	width: 100%;
	height: 123px;	
}