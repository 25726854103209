.section-label-wide {
	font-weight: 700;
	font-size: 32px;
	margin-top: 25px;
	margin-bottom: 0px;
	text-align: center;
}

.section-label-narrow {
	font-weight: 700;
	font-size: 27px;
	margin-top: 25px;
	margin-bottom: 0px;
	text-align: center;
}