/* tablet/desktop */

.menu-item-cards-wide {
	width: 100%;
}

.menu-item-row-wide {
	width: 100%;
	display: flex;
	align-items: flex-start;
}

.menu-item-card-image-container-wide {
	height: 160px;
	width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	display: flex;
	justify-content: center;
	align-items: center;
}

.menu-item-card-body-wide-nodesc-left {
	height: auto;
	width: 23.125%;
	overflow: hidden;
	text-overflow: ellipsis;
	display: inline-block;
	margin-bottom: -25px;
}

.menu-item-card-body-wide-left {
	height: 300px;
	width: 23.125%;
	overflow: hidden;
	text-overflow: ellipsis;
	display: inline-block;
	margin-bottom: -25px;
}

.menu-item-card-body-wide-nodesc-inner {
	height: auto;
	width: 23.125%;
	overflow: hidden;
	text-overflow: ellipsis;
	display: inline-block;
	margin-bottom: -25px;
	padding-left: 2.5%;
}

.menu-item-card-body-wide-inner {
	height: 300px;
	width: 23.125%;
	overflow: hidden;
	text-overflow: ellipsis;
	display: inline-block;
	margin-bottom: -25px;
	padding-left: 2.5%;
}

.menu-item-card-body-wide-nodesc-right {
	height: auto;
	width: 23.125%;
	overflow: hidden;
	text-overflow: ellipsis;
	display: inline-block;
	margin-bottom: -25px;
	padding-left: 2.5%;
}

.menu-item-card-body-wide-right {
	height: 300px;
	width: 23.125%;
	overflow: hidden;
	text-overflow: ellipsis;
	display: inline-block;
	margin-bottom: -25px;
	padding-left: 2.5%;
}

.menu-item-card-wide {
	float: left;
	width: 100%;
	margin-bottom: 20px;
	overflow: hidden;
	text-overflow: ellipsis;
	margin-top: 20px;
}

.menu-item-card-overlay-wide {
	position: absolute;
	width: 20.95%;
	transform: translate(-50%, 0px);
	height: 255px;
	border-radius: 8px;
/*	background-image: linear-gradient(135deg, 
		var(--navbar-color) 0%, rgba(26,34,40,0.5) 5%,
		var(--navbar-color) 5%, rgba(26,34,40,0.5) 10%,
		var(--navbar-color) 10%, rgba(26,34,40,0.5) 15%,
		var(--navbar-color) 15%, rgba(26,34,40,0.5) 20%,
		var(--navbar-color) 20%, rgba(26,34,40,0.5) 25%,
		var(--navbar-color) 25%, rgba(26,34,40,0.5) 30%,
		var(--navbar-color) 30%, rgba(26,34,40,0.5) 35%,
		var(--navbar-color) 35%, rgba(26,34,40,0.5) 40%,
		var(--navbar-color) 40%, rgba(26,34,40,0.5) 45%,
		var(--navbar-color) 45%, rgba(26,34,40,0.5) 50%,
		var(--navbar-color) 50%, rgba(26,34,40,0.5) 55%,
		var(--navbar-color) 55%, rgba(26,34,40,0.5) 60%,
		var(--navbar-color) 60%, rgba(26,34,40,0.5) 65%,
		var(--navbar-color) 65%, rgba(26,34,40,0.5) 70%,
		var(--navbar-color) 70%, rgba(26,34,40,0.5) 75%,
		var(--navbar-color) 75%, rgba(26,34,40,0.5) 80%,
		var(--navbar-color) 80%, rgba(26,34,40,0.5) 85%,
		var(--navbar-color) 85%, rgba(26,34,40,0.5) 90%,
		var(--navbar-color) 90%, rgba(26,34,40,0.5) 95%,
		var(--navbar-color) 95%, rgba(26,34,40,0.5) 100%
	);*/
	background: rgba(0,0,0,0.3);
	z-index: 1;
	display: none;
}

.menu-item-card-image-wide {
	width: 100%;
	height: 100%;
	overflow: hidden;
	margin: auto;
	object-fit: cover;
	border-radius: 8px;
}

.menu-item-card-image-placeholder-wide {
	width: 50%;
	margin: auto;
}

.menu-item-card-title-wide {
	font-weight: 700;
	font-size: 16px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	margin-block-start: 0em;
    margin-block-end: 0em;
    display: block;
    width: 100%;
    margin-top: 5px;
}

.menu-item-card-description-wide {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	float: left;
	overflow: hidden;
	white-space: normal;
	text-overflow: ellipsis;
	margin-block-start: 0.5em;
    margin-block-end: 0em;
    font-size: 14px;
	width: 90%;
	margin-top: 0px;
	color: rgb(70, 70, 70);
}

.menu-item-card-price-wide {
	margin-block-start: 0em;
    margin-block-end: 0em;
    display: block;
}


/* end tablet/desktop */


/* mobile */

.menu-item-cards-narrow {
	width: 100%;
	overflow: hidden;
}

.menu-item-row-narrow {
	width: 100%;
	display: flex;
	align-items: flex-start;
}

.menu-item-card-image-container-narrow {
	height: 160px;
	width: 100%;
	border-radius: 8px;
	overflow: hidden;
	text-overflow: ellipsis;
	display: flex;
	justify-content: center;
	align-items: center;
}

.menu-item-card-body-narrow-nodesc-left {
	height: auto;
	width: 50%;
	overflow: hidden;
	text-overflow: ellipsis;
	display: inline-block;
	margin-bottom: -25px;
}

.menu-item-card-body-narrow-left {
	height: 300px;
	width: 50%;
	overflow: hidden;
	text-overflow: ellipsis;
	display: inline-block;
	margin-bottom: -25px;
}

.menu-item-card-body-narrow-nodesc-right {
	height: auto;
	width: 50%;
	overflow: hidden;
	text-overflow: ellipsis;
	display: inline-block;
	margin-bottom: -25px;
	padding-left: 2.5%;
}

.menu-item-card-body-narrow-right {
	height: 300px;
	width: 50%;
	overflow: hidden;
	text-overflow: ellipsis;
	display: inline-block;
	margin-bottom: -25px;
	padding-left: 2.5%;
}

.menu-item-card-narrow {
	float: left;
	width: 100%;
	margin-bottom: 20px;
	overflow: hidden;
	text-overflow: ellipsis;
	margin-top: 20px;
}

.menu-item-card-image-narrow {
	width: 100%;
	height: 100%;
	overflow: hidden;
	margin: auto;
	object-fit: cover;
}

.menu-item-card-image-placeholder-narrow {
	width: 50%;
	margin: auto;
}

.menu-item-card-title-narrow {
	font-weight: 700;
	font-size: 16px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	margin-block-start: 0em;
    margin-block-end: 0em;
    display: block;
    margin-top: 5px;
}

.menu-item-card-description-narrow {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	float: left;
	overflow: hidden;
	white-space: normal;
	text-overflow: ellipsis;
	margin-block-start: 0.5em;
    margin-block-end: 0em;
    font-size: 14px;
    margin-top: 0px;
    color: rgb(70, 70, 70);
}

.menu-item-card-price-narrow {
	margin-block-start: 0em;
    margin-block-end: 0em;
    display: block;
}

/* end mobile */
