.restaurant-profile-info-wide {
	align-content: center;
	justify-content: center;
	text-align: center;
}

.restaurant-profile-info-narrow {
	align-content: center;
	justify-content: center;
	text-align: center;
}

.restaurant-name-wide {
	font-weight: 700;
	font-size: 45px;
	font-family: 'Anek Telugu', sans-serif;
	display: inline-block;
	margin: 0 auto;
	margin-bottom: -5px;
	margin-top: 25px;
}

.restaurant-name-narrow {
	font-weight: 700;
	font-size: 30px;
	font-family: 'Anek Telugu', sans-serif;
	display: inline-block;
	margin: 0 auto;
	margin-bottom: 0px;
	margin-top: 10px;
}

.restaurant-hours-wide {
	color: grey;
	display: inline-block;
	margin: 0 auto;
}

.restaurant-hours-narrow {
	color: grey;
	display: inline-block;
	margin: 0 auto;
}

.restaurant-profile-open-status-open {
	color: #02e364;
	font-weight: 900;
}

.qr-code-header-wide {
	width: 50px;
	display: inline-block;
	margin-left: 20px;
	position: relative;
	top: 7px;
}

.qr-code-header-narrow {
	display: none;
}
