.restaurant-image-container {
	width: 100%;
	max-height: 200px;
	overflow: hidden;
	margin: 0 auto;
	position: relative;
}

.restaurant-image {
	width: 100%;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}


/*.main {
  width: 300px;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  height: 200px;
}*/

/*img.absolute {
  left: 50%;
  margin-left: -200px;
  position: absolute;
}*/