.menu-section-scroller-body-wide {
	overflow-y: scroll;
	white-space: nowrap;
	background: white;
	padding-top: 12px;
	/* hide scrollbar for IE, Edge and Firefox */
	-ms-overflow-style: none;
	scrollbar-width: none;
}
.menu-section-scroller-body-narrow {
	overflow-y: scroll;
	white-space: nowrap;
	position: sticky;
	top: 0px;
	background: white;
	padding-top: 8px;
	/* hide scrollbar for IE, Edge and Firefox */
	-ms-overflow-style: none;
	scrollbar-width: none;
}

.menu-section-scroller-body-nested-wide {
	margin-left: -25px;
}
.menu-section-scroller-body-nested-narrow {
	margin-left: -10px;
}

/* hide scrollbar for chrome, safari and opera */
.menu-section-scroller-body-wide::-webkit-scrollbar {
	display: none;
  }
  
.menu-section-scroller-body-narrow::-webkit-scrollbar {
	display: none;
}

.active {
	color: black;
}

.underline {
	height: 4px;
	margin-top: 10px;
}

.active .underline {
	transition: background-color 0.3s ;
	height: 4px;
	background-color: black;
	border-radius: 100px;
}

.info-card-wide {
	padding-top: 12px;
	/*clear: both;*/
	display: inline-block;
	padding-left: 25px;
	padding-right: 25px;
	font-weight: 700;
	font-size: 24px;
	color: black;
}

.info-card-wide:hover{
	cursor: pointer;
}

.info-card-narrow {
	padding-top: 12px;
	/*clear: both;*/
	display: inline-block;
	padding-left: 10px;
	padding-right: 10px;
	font-weight: 700;
	font-size: 16px;
	color: black;
}

.info-card-container-wide {

}

.info-card-container-narrow {
	margin-left: 0;
	padding-bottom: 0;
}

.item-select {
	border: none;
	background: transparent;
}