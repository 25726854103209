.navbar-bottom {
	width: 100%;
	height: 50px;
	border-top: solid 1px var(--border-color);
	position: sticky;
	bottom: 0px;
	/*left: 50%;*/
	left: 0%;
	/*transform: translate(-50%, 0%);*/
	background: white;
}

.home-icon {
	display: inline-block;
}

.account-icon {
	display: inline-block;
}