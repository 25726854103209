.add-to-order-button-wide {
	background: var(--logo-color);
	width: 100%;
	height: 50px;
	border-radius: 25px;
	border: none;
    position: sticky;
    bottom: 20px;
}

.add-to-order-button-narrow {
	background: var(--logo-color);
	width: 100%;
	height: 50px;
	border-radius: 25px;
	border: none;
    position: sticky;
    bottom: 20px;
}

.add-to-order-button-wide-restaurant-facing-app-kitchen {
	background: var(--navbar-color);
	width: 100%;
	height: 50px;
	border-radius: 25px;
	border: none;
    position: sticky;
    bottom: 20px;
}

.add-to-order-button-narrow-restaurant-facing-app-kitchen {
	background: var(--navbar-color);
	width: 100%;
	height: 50px;
	border-radius: 25px;
	border: none;
    position: sticky;
    bottom: 20px;
}

.add-to-order-button-wide-restaurant-facing-app-admin {
	background: var(--navbar-color);
	width: 100%;
	height: 50px;
	border-radius: 25px;
	border: none;
    position: sticky;
    bottom: 20px;
}

.add-to-order-button-narrow-restaurant-facing-app-admin {
	background: var(--navbar-color);
	width: 100%;
	height: 50px;
	border-radius: 25px;
	border: none;
    position: sticky;
    bottom: 20px;
}

.add-to-order-span {
	color: white;
	font-weight: 700;
	font-family: 'Anek Telugu', sans-serif;
	padding-top: 4px;
	font-size: 18px;
}