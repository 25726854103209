/*@media only screen and (min-width: 540px) {
	.confirm-order-button-wide {
		background: var(--logo-color);
		width: 86%;
		height: 50px;
		border-radius: 25px;
		border: none;
		position: sticky;
		bottom: 20px;
		top: 88%;
		margin-left: 7%;
	}
}

@media only screen and (min-width: 600px) {
	.confirm-order-button-wide {
		background: var(--logo-color);
		width: 86%;
		height: 50px;
		border-radius: 25px;
		border: none;
		position: sticky;
		bottom: 20px;
		top: 91%;
		margin-left: 7%;
	}
}

@media only screen and (min-width: 1024px) {
	.confirm-order-button-wide {
		background: var(--logo-color);
		width: 86%;
		height: 50px;
		border-radius: 25px;
		border: none;
		position: sticky;
		bottom: 20px;
		top: 86%;
		margin-left: 7%;
	}
}

@media only screen and (min-width: 1280px) {
	.confirm-order-button-wide {
		background: var(--logo-color);
		width: 86%;
		height: 50px;
		border-radius: 25px;
		border: none;
		position: sticky;
		bottom: 20px;
		top: 88%;
		margin-left: 7%;
	}
}

.confirm-order-button-narrow {
	background: var(--logo-color);
	width: 86%;
	height: 50px;
	border-radius: 25px;
	border: none;
    position: sticky;
    bottom: 20px;
	top: 90%;
	margin-left: 7%;
}*/

.confirm-order-span {
	color: white;
	font-weight: 700;
	font-family: 'Anek Telugu', sans-serif;
	padding-top: 4px;
	font-size: 18px;
}

.confirm-order-button-wide {
	background: var(--logo-color);
	width: 86%;
	height: 50px;
	border-radius: 25px;
	border: none;
	position: sticky;
	bottom: 20px;
	margin-left: 7%;
}

.confirm-order-button-narrow {
	background: var(--logo-color);
	width: 86%;
	height: 50px;
	border-radius: 25px;
	border: none;
    position: sticky;
    bottom: 20px;
	margin-left: 7%;
}

.confirm-order-button-wide {
    position: sticky;
    bottom: 20px;
}