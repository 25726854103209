.overlay {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .7);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
}

.modalOptions-wide {
  position: fixed;
  z-index: 20;
  background: #fff;
  width: 100%;
  height: 95%;
  max-width: 600px;
  border-radius: 25px;
  overflow: scroll;
  /* Center the modalOptions */
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  text-align: left;
}

.modalOptions-wide::-webkit-scrollbar {
  display: none;
}

.modalOptions-narrow {
  position: fixed;
  z-index: 20;
  background: #fff;
  width: 100%;
  height: 100%;
  max-width: 500px;
  overflow: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  /* Center the modalOptions */
  top: 0%;
  left: 50%;
  transform: translateX(-50%);
  text-align: left;
}

.modalOptions-narrow::-webkit-scrollbar {
  display: none;
}

.modalOptions-header-wide {
  display: block;
  justify-content: space-between;
  margin-top: -58px;
}

.modalOptions-header-narrow {
  display: block;
  justify-content: space-between;
  margin-top: -54px;
}

.modalOptions-title {
  margin-top: 0px;
  margin-bottom: 0px;
}

.modalOptions-title-input {
  margin-top: 0px;
  margin-bottom: 10px;
  display: block;
  font-size: 1.5em;
  font-weight: bold;
}

.modalOptions-description {
  margin-bottom: -15px;
  margin-top: 0px;
}

.modalOptions-description-input {
  margin-bottom: -15px;
  margin-top: 0px;
  word-break: break-word;
  /*width: calc(100% - 7px);*/
  width: 100%!important;
  height: auto;
  font-family: 'Anek Telugu', sans-serif;
}

.modalOptions-price {
  margin-top: 0px;
  margin-bottom: 0px;
  font-weight: 700;
}

.modalOptions-price-input {
  margin-top: 0px;
  margin-bottom: 10px;
  font-weight: 700;
}

.modalOptions-image-container {
  width: 100%;
  display: block;
  max-height: 30%;
  overflow: hidden;
}

.modalOptions-image-container-empty {
  width: 100%;
  display: block;
  max-height: 30%;
  overflow: hidden;
  height: 50px;
}

.modalOptions-image {
  width: 100%;
  display: block;
  max-height: 30%;
}

.modalOptions-main {
  padding-top: 5%;
  padding-left: 5%;
  padding-right: 5%;
}

.button {
  background: green;
  border: none;
  padding: 10px 20px;
  color: #fff;
  cursor: pointer;

}

.button:hover {
  background: orange;
}

.modalOptions-options-section-body {
  width: 100%;
}

.modalOptions-option-body {
  margin-bottom: 0px;
  padding-bottom: 10px;
  padding-top: 12px;
  border-bottom: solid 1px lightgrey;
}

.modalOptions-option-body-restaurant-facing-app-kitchen {
  margin-bottom: 0px;
  padding-bottom: 10px;
  padding-top: 22px;
  border-bottom: solid 1px lightgrey;
}

.modalOptions-option-body-restaurant-facing-app-admin {
  margin-bottom: 0px;
  padding-bottom: 10px;
  padding-top: 22px;
  border-bottom: solid 1px lightgrey;
}

.modalOptions-option-input {
  vertical-align: top;
  height: 14px;
  width: 14px;
  /*margin-top: 5px;*/
}

.modalOptions-option-position-icon {
  vertical-align: middle;
  height: 18px;
  width: 18px;
}

.modalOptions-option-title {
  margin-left: 4%;
  margin-top: 20px;
}

.modalOptions-option-cost {
  float: right;
  margin-right: 0%;
  color: lightgrey;
}

.modalOptions-option-title-input {
  margin-left: 4%;
  margin-top: -10px;
}

.modalOptions-option-cost-input {
  float: right;
  margin-right: 0%;
  color: lightgrey;
  margin-top: -10px;
}

.modalOptions-input {
  border-radius: 8px;
  border: solid 1px var(--border-color);
  padding: 10px;
  /*margin-top: -10px;*/
}

.modalOptions-option-cost-active {
  font-weight: 700;
  color: rgb(126, 126, 126);
}

.modalOptions-options-section-title {
  font-weight: 700;
  margin-top: 40px;
  margin-bottom: -15px;
}

.modalOptions-options-section-title-input {
  font-weight: 700;
  margin-top: 40px;
  margin-bottom: 0px;
}

.modalOptions-options-section-title-input-position-button-container {
  margin-left: 10px;
  display: inline-block;
}

.modalOptions-options-section-title-input-position-button {
  margin-left: 5px;
  height: 37.5px;
  width: 37.5px;
  border: solid 1px var(--border-color);
  border-radius: 8px;
  background: transparent;
}

.modalOptions-options-section-limit-string {
  margin-bottom: 0px;
}

.modalOptions-special-instructions-input {
  border-radius: 8px;
  width: 95%;
  height: 150px;
  border: none;
  display: block;
  margin-bottom: 25px;
  margin-top: 0px;
  resize: none;
  font-family: 'Anek Telugu', sans-serif;
  font-size: 16px;
  padding: 10px;
  background: rgb(245, 245, 245);
}

.modalOptions-special-instructions-label {
  margin-top: 25px;
  margin-bottom: 25px;
  font-weight: 700;
}

.modalOptions-quantityButton-container{
  display: flex;
  justify-content: center;
}

.modalOptions-quantityButton{
  display: flex;
  justify-content: space-between;
  border: none;
  background: rgb(245, 245, 245);
  border-radius: 30px;
  width: 50%;
  margin-bottom: 40px;
}

.modalOptions-quantityButton-btn{
  border: none;
  background: none;
  color: black;
  font-weight: 700;
  font-size: large;
  width: 50%;
}

.modalOptions-quantityButton-quantity {
  width: 20%;
  display: flex;
  justify-content: center;
  font-weight: 700;
}

.close-button {
  border-radius: 25px;
  border: none;
  padding: 12px;
  padding-top: 0px;
  padding-bottom: 0px;
  cursor: pointer;
  color: black;
  font-size: 40px;
  z-index: 30;
  position: sticky;
  left: 8px;
  top: 8px;
  background: white;
  /*left: -6px;*/
}

.options-disclaimer {
  color: rgba(0, 0, 0, 0.3);
  font-size: 13px;
  margin-bottom: -15px;
}

.modalOptions-spacer-restaurant-facing-app-admin {
  height: 50px;
}

.modalOptions-spacer-restaurant-facing-app-kitchen {
  height: 50px;
}

.modalOptions-option-section-limit-container {
  display: block;
  margin-top: 10px;
  margin-bottom: 0px;
}

.modalOptions-option-section-min-input {
  width: 25px;
  text-align: center;
  margin-right: 10px;
}

.modalOptions-option-section-max-input {
  width: 25px;
  text-align: center;
  margin-left: 10px;
  margin-right: 10px;
}
